import React from "react"
import Layout from "@/components/layout"
import { Work } from "../../components/work"

export default function Header() {
  return (
    <Layout currentPage="index">
      <Work work="editing-reel" />
    </Layout>
  )
}
